export const maintenancePeriods = [
    {value: 'initial', name: 'Inicial'},
    {value: 'once', name: 'Una vez'},
    {value: 'monthly', name: 'Mensual'},
    {value: 'bimensual', name: 'Bimensual'},
    {value: 'quarterly', name: '3 meses'},
    {value: 'fourthly', name: '4 meses'},
    {value: 'semester', name: '6 meses'},
    {value: 'annual', name: '1 año'},
    {value: 'biennial', name: '2 años'},
]
